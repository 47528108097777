import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { IconDirective } from '@core/shared/util';

@Component({
  selector: 'mpctm-predecessor-element-tile',
  standalone: true,
  templateUrl: './predecessor-element-tile.component.html',
  styleUrls: ['./predecessor-element-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, MatIconModule, IconDirective],
})
export class PredecessorElementTileComponent {
  @Input({ required: true }) icon!: string;

  @Input({ required: true }) predecessorName!: string;

  @Input({ required: true }) predecessorNumber!: string | null;

  @Input({ required: true }) predecessorNumberLabel!: string;
}
