import { DecimalPipe, PercentPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

import { ArticlePricePercentageDifferencePipe } from './article-price-percentage-difference.pipe';

@Component({
  selector: 'mpctm-condition-article-price',
  standalone: true,
  templateUrl: './condition-article-price.component.html',
  styleUrl: './condition-article-price.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DecimalPipe, PercentPipe, ArticlePricePercentageDifferencePipe],
})
export class ConditionArticlePriceComponent {
  readonly articlePrice: InputSignal<number> = input.required<number>();

  readonly percentageDifference: InputSignal<number | undefined> = input<number>();
}
