import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

import { ContractStatusType } from '@mp/contract-management/common/domain';

import { ContractStatusComponent } from '../contract-status/contract-status.component';

@Component({
  selector: 'mpctm-status-management-bar',
  standalone: true,
  templateUrl: './status-management-bar.component.html',
  styleUrls: ['./status-management-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgFor, MatLegacyButtonModule, ContractStatusComponent],
})
export class StatusManagementBarComponent {
  @Input({ required: true }) set statusType(statusType: ContractStatusType) {
    this._statusType = statusType;
    this.availableContractStatuses = this.setupAvailableContractStatuses();
  }

  get statusType(): ContractStatusType {
    return this._statusType;
  }

  private _statusType!: ContractStatusType;

  @Input() statusLabel = '';

  availableContractStatuses: ContractStatusType[] = [];

  @Output() readonly statusTypeChange: EventEmitter<ContractStatusType> = new EventEmitter<ContractStatusType>();

  changeStatusType(statusType: ContractStatusType): void {
    this.statusTypeChange.emit(statusType);
  }

  trackByFn(index: number): number {
    return index;
  }

  private setupAvailableContractStatuses(): ContractStatusType[] {
    switch (this.statusType) {
      case ContractStatusType.Created:
        return [ContractStatusType.Approved];
      case ContractStatusType.Modified:
        return [ContractStatusType.Approved, ContractStatusType.Archived];
      case ContractStatusType.Approved:
        return [ContractStatusType.Modified, ContractStatusType.Archived];
      case ContractStatusType.Archived:
        return [ContractStatusType.Modified];
      default:
        return [];
    }
  }
}
