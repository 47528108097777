@if (dateValue(); as dateValue) {
  <span class="font-bold truncate" *ngrxLet="dateValue | date: 'dd.MM.yyy' as formattedDate" [title]="formattedDate">
    {{ formattedDate }}</span
  >
  <span class="text-left truncate" *ngrxLet="dateValue | date: 'HH:mm:ss' as formattedTime" [title]="formattedTime">
    {{ formattedTime }}</span
  >
} @else {
  <span class="font-bold">-</span>
}
