import { DatePipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { UserModification } from '@mp/contract-management/common/domain';

import { ContractUserInfoPipe } from './contract-user-info.pipe';

@Component({
  selector: 'mpctm-contract-user-info',
  standalone: true,
  templateUrl: './contract-user-info.component.html',
  styleUrls: ['./contract-user-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, DatePipe, ContractUserInfoPipe],
})
export class ContractUserInfoComponent {
  @Input() userModification: UserModification | null = null;

  @Input() infoTitle = '';
}
