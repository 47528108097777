<div class="status-bar">
  <div class="status-bar__current">
    <span class="status-bar__current-label truncate" title="statusLabel">{{ statusLabel }}</span>
    <mpctm-contract-status class="truncate" [statusType]="statusType" />
  </div>

  <div class="status-bar__available">
    <span class="status-bar__available-label truncate" title="Ändern in">Ändern in</span>

    <button
      *ngFor="let availableContractStatus of availableContractStatuses; trackBy: trackByFn"
      mat-raised-button
      class="status-bar__available-change-status-button"
      (click)="changeStatusType(availableContractStatus)"
    >
      <mpctm-contract-status [statusType]="availableContractStatus" />
    </button>
  </div>
</div>
