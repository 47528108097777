<mp-info-card>
  <div class="mailing-address-card-title truncate" title="Postanschrift">Postanschrift</div>
  <div class="mailing-address-card-content">
    <div class="truncate" [title]="mailingAddress.recipient">
      {{ mailingAddress.recipient }}
    </div>

    <div class="truncate" [title]="mailingAddress.street">
      {{ mailingAddress.street }}
    </div>

    <div class="truncate" [title]="mailingAddress.zipCode + ' ' + mailingAddress.city">
      {{ mailingAddress.zipCode }} {{ mailingAddress.city }}
    </div>

    <div *ngIf="mailingAddress.countryCode" class="truncate" [title]="mailingAddress.countryCode">
      {{ mailingAddress.countryCode }}
    </div>
  </div>
</mp-info-card>
