<div class="contract-validity-label truncate" title="Gültig">Gültig</div>

<div class="contract-validity-date truncate" *ngrxLet="validFrom | date: 'dd.MM.yyyy' as validFrom">
  von
  <span class="contract-validity-value" [title]="validFrom">{{ validFrom ?? '-' }}</span>
</div>

<div class="contract-validity-date truncate" *ngrxLet="validUntil | date: 'dd.MM.yyyy' as validUntil">
  bis
  <span class="contract-validity-value" [title]="validUntil">{{ validUntil ?? '-' }}</span>
</div>
