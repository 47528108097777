import { SignStatus } from '@mp/contract-management/common/domain';

export function getSignStatusCssModifier(signStatus: SignStatus): string {
  switch (signStatus) {
    case SignStatus.SIGNED:
      return '--success';
    case SignStatus.UNSIGNED:
      return '--error';
    case SignStatus.PENDING_CONFIRMATION:
      return '--warn';
  }
}
