import { Graduation, GraduationLevel } from '@core/ui';
import { ContractAgreementGraduation, ContractAgreementGraduationLevel } from '@mp/contract-management/common/domain';

export function mapAgreementGraduationsToGraduations(
  agreementGraduations: ContractAgreementGraduation[] | null,
): Graduation[] {
  if (!agreementGraduations) {
    return [];
  }

  return agreementGraduations.map(({ graduationName, levels }) => ({
    graduationNames: [graduationName],
    levels: mapAgreementLevelsToGraduationLevels(levels),
  }));
}

function mapAgreementLevelsToGraduationLevels(levels: ContractAgreementGraduationLevel[] | null): GraduationLevel[] {
  if (!levels) {
    return [];
  }

  return levels.map(({ effect, requirement }) => ({
    requirements: [requirement],
    effect,
  }));
}
