<div [class]="getStatusClass()">
  <mat-icon class="contract-status__icon" [mpIcon]="statusType | mpctmContractStatusIcon" />
  <span
    *ngrxLet="statusType | mpctmContractStatusLabel as statusLabel"
    class="contract-status__label truncate"
    [title]="statusLabel"
  >
    {{ statusLabel }}
  </span>
</div>
