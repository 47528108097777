import { ChangeDetectionStrategy, Component, InputSignal, Signal, computed, input } from '@angular/core';

import { AgreementInfoListComponent, Graduation, GraduationsComponent } from '@core/ui';
import { ContractAgreement, ContractAgreementGraduation } from '@mp/contract-management/common/domain';

import { mapAgreementGraduationsToGraduations } from './contract-agreement-utils';

@Component({
  selector: 'mpctm-contract-agreement',
  standalone: true,
  templateUrl: './contract-agreement.component.html',
  styleUrl: './contract-agreement.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AgreementInfoListComponent, GraduationsComponent],
})
export class ContractAgreementComponent {
  readonly agreement: InputSignal<ContractAgreement> = input.required<ContractAgreement>();

  readonly agreementGraduations: Signal<Graduation[]> = computed(() => {
    const agreementGraduations: ContractAgreementGraduation[] | null = this.agreement().graduations;

    return mapAgreementGraduationsToGraduations(agreementGraduations);
  });
}
