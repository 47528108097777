import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { LetDirective } from '@ngrx/component';

@Component({
  selector: 'mpctm-condition-articles-count',
  standalone: true,
  templateUrl: './condition-articles-count.component.html',
  styleUrl: './condition-articles-count.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DecimalPipe, LetDirective],
})
export class ConditionArticlesCountComponent {
  readonly articlesCount: InputSignal<number> = input.required<number>();
  readonly relevantArticlesCount: InputSignal<number> = input.required<number>();
}
