import { formatDate } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

import { UserModification } from '@mp/contract-management/common/domain';

@Pipe({
  name: 'mpctmContractUserInfo',
  standalone: true,
})
export class ContractUserInfoPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform({ user, timestamp }: UserModification): string {
    return `${user?.name}, ${formatDate(timestamp, 'dd.MM.yyyy', this.locale)}`;
  }
}
