import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { ImagePathPipe } from '@core/shared/util';
import { ArticleTypeIconComponent, ImageComponent } from '@core/ui';
import { ConditionArticle } from '@mp/contract-management/common/domain';

@Component({
  selector: 'mpctm-condition-article',
  standalone: true,
  templateUrl: './condition-article.component.html',
  styleUrl: './condition-article.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, ArticleTypeIconComponent, ImageComponent, ImagePathPipe],
})
export class ConditionArticleComponent {
  readonly conditionArticle: InputSignal<ConditionArticle> = input.required<ConditionArticle>();
}
