import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'mpctm-condition-data-from-contract-icon',
  standalone: true,
  templateUrl: './condition-data-from-contract-icon.component.html',
  styleUrls: ['./condition-data-from-contract-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, MatTooltipModule],
})
export class ConditionDataFromContractIconComponent {
  readonly tooltipMessage: InputSignal<string> = input<string>('Vererbt von Vertrag');
}
