import { ChangeDetectionStrategy, Component, InputSignal, Signal, computed, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { SignStatus } from '@mp/contract-management/common/domain';
import { getSignStatusCssModifier } from '@mp/contract-management/common/util';

@Component({
  selector: 'mpctm-sign-status-info-icon',
  standalone: true,
  templateUrl: './sign-status-info-icon.component.html',
  styleUrl: './sign-status-info-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule],
})
export class SignStatusInfoIconComponent {
  readonly signStatus: InputSignal<SignStatus> = input.required<SignStatus>();

  protected readonly colorModifier: Signal<string> = computed(() => getSignStatusCssModifier(this.signStatus()));
}
