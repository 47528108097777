import { ChangeDetectionStrategy, Component, InputSignal, Signal, computed, input } from '@angular/core';

import { CustomChipComponent } from '@core/ui';
import { SignStatus, signStatusDescriptions } from '@mp/contract-management/common/domain';
import { getSignStatusCssModifier } from '@mp/contract-management/common/util';

@Component({
  selector: 'mpctm-condition-sign-status',
  standalone: true,
  templateUrl: './condition-sign-status.component.html',
  styleUrl: './condition-sign-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': 'class()',
  },
  imports: [CustomChipComponent],
})
export class ConditionSignStatusComponent {
  private readonly componentCssClass = 'mpctm-condition-sign-status';

  readonly conditionSignStatus: InputSignal<SignStatus> = input.required<SignStatus>();
  readonly small: InputSignal<boolean> = input<boolean>(false);

  protected readonly class: Signal<string> = computed(() => {
    return `${this.componentCssClass} ${this.getComponentStateCssClass()}`;
  });

  protected readonly signStatusLabel: Signal<string> = computed(
    () => signStatusDescriptions[this.conditionSignStatus()] || '',
  );

  protected getComponentStateCssClass(): string {
    const signStatus: SignStatus = this.conditionSignStatus();
    const stateCssModifier: string = getSignStatusCssModifier(signStatus);

    return this.componentCssClass + stateCssModifier;
  }
}
