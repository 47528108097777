<mpsm-supplier-icon [supplier]="supplier" />

<div class="supplier-info truncate">
  <span class="supplier-info__external-number" [title]="'Lieferant ' + (supplier.externalNumber || '')">
    Lieferant
    <a class="supplier-info__external-link truncate" [attr.href]="supplierPageNewWindowLink" target="_blank">
      <span *ngIf="supplier.externalNumber" class="font-mono truncate">
        {{ supplier.externalNumber }}
      </span>
      <mat-icon *ngIf="supplierPageNewWindowLink" class="supplier-info__external-url-icon material-icons-outlined">
        north_east
      </mat-icon>
    </a>
  </span>

  <span class="supplier-info__name truncate" [title]="supplier.name">{{ supplier.name }}</span>

  <span
    *ngIf="supplier.shortName"
    class="supplier-info__short-name truncate"
    [title]="'Kurzname ' + supplier.shortName"
  >
    Kurzname <span class="supplier-info__short-name-value">{{ supplier.shortName }}</span>
  </span>
</div>
