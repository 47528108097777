import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

import { SupplierBasic } from '@mpsm/shared/domain';
import { SupplierIconComponent } from '@mpsm/shared/ui';

@Component({
  selector: 'mpctm-condition-supplier',
  standalone: true,
  templateUrl: './condition-supplier.component.html',
  styleUrl: './condition-supplier.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SupplierIconComponent],
})
export class ConditionSupplierComponent {
  readonly supplier: InputSignal<SupplierBasic> = input.required<SupplierBasic>();
}
