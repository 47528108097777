import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

import { ContractAgreement } from '@mp/contract-management/common/domain';

import { ContractAgreementComponent } from './contract-agreement/contract-agreement.component';

@Component({
  selector: 'mpctm-contract-agreements',
  standalone: true,
  templateUrl: './contract-agreements.component.html',
  styleUrl: './contract-agreements.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ContractAgreementComponent],
})
export class ContractAgreementsComponent {
  readonly agreements: InputSignal<ContractAgreement[]> = input.required<ContractAgreement[]>();
}
