import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mpctmArticlePricePercentageDifference',
  standalone: true,
})
export class ArticlePricePercentageDifferencePipe implements PipeTransform {
  transform(priceDifference: string | null): string {
    if (!priceDifference) {
      return '';
    }

    if (priceDifference.startsWith('-')) {
      return priceDifference;
    }

    return `+${priceDifference}`;
  }
}
