import { Pipe, PipeTransform } from '@angular/core';

import { ContractStatusType } from '@mp/contract-management/common/domain';

@Pipe({
  name: 'mpctmContractStatusIcon',
  standalone: true,
})
export class ContractStatusIconPipe implements PipeTransform {
  transform(contractStatusType: ContractStatusType): string {
    switch (contractStatusType) {
      case ContractStatusType.Approved:
        return 'check_circle';
      case ContractStatusType.Archived:
        return '@archived';
      case ContractStatusType.Modified:
        return 'edit';
      case ContractStatusType.Created:
        return 'upcoming';

      default:
        return '';
    }
  }
}
