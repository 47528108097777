<div class="predecessor-tile">
  <mat-icon class="predecessor-tile__icon" [mpIcon]="icon" />

  <div class="predecessor-info truncate">
    <span class="predecessor-info__name truncate" [title]="predecessorName">{{ predecessorName }} </span>

    <div *ngIf="predecessorNumber" class="predecessor-info__number">
      <span class="predecessor-info__number-label truncate" [title]="predecessorNumberLabel">
        {{ predecessorNumberLabel }}
      </span>
      <span class="font-mono font-bold truncate" [title]="predecessorNumber">
        {{ predecessorNumber }}
      </span>
    </div>
  </div>
</div>
