import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

import { CustomChipComponent } from '@core/ui';

@Component({
  selector: 'mpctm-entity-number-chip',
  standalone: true,
  templateUrl: './entity-number-chip.component.html',
  styleUrl: './entity-number-chip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CustomChipComponent],
})
export class EntityNumberChipComponent {
  readonly entityNumber: InputSignal<string> = input.required<string>();
}
