import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { InfoCardComponent } from '@core/ui';
import { ContractMailingAddress } from '@mp/contract-management/common/domain';

@Component({
  selector: 'mpctm-contract-mailing-address',
  standalone: true,
  templateUrl: './contract-mailing-address.component.html',
  styleUrls: ['./contract-mailing-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, InfoCardComponent],
})
export class ContractMailingAddressComponent {
  @Input({ required: true }) mailingAddress!: ContractMailingAddress;
}
