<span class="price">{{ articlePrice() | number: '1.4' }}</span>

@if (percentageDifference(); as percentageDifference) {
  <span
    class="price-difference"
    [class]="{
      'price-difference--better': percentageDifference < 0,
      'price-difference--worse': percentageDifference > 0
    }"
  >
    {{ percentageDifference | percent | mpctmArticlePricePercentageDifference }}</span
  >
}
