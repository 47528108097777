import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLinkWithHref } from '@angular/router';

import { SupplierBasic } from '@mpsm/shared/domain';
import { SupplierIconComponent } from '@mpsm/shared/ui';

@Component({
  selector: 'mpctm-supplier-info-header',
  standalone: true,
  templateUrl: './supplier-info-header.component.html',
  styleUrls: ['./supplier-info-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, RouterLinkWithHref, MatIconModule, SupplierIconComponent],
})
export class SupplierInfoHeaderComponent {
  @Input() supplier!: SupplierBasic;

  @Input()
  set supplierPageLink(value: string | undefined) {
    this.supplierPageNewWindowLink = this.getSupplierPageNewWindowLink(value);
  }

  supplierPageNewWindowLink: string | undefined;

  constructor(private readonly router: Router) {}

  private getSupplierPageNewWindowLink(relativeLink: string | undefined): string | undefined {
    if (!relativeLink) {
      return undefined;
    }

    return this.router.serializeUrl(this.router.createUrlTree([relativeLink]));
  }
}
