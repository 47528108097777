import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mpctm-page-footer',
  standalone: true,
  templateUrl: './page-footer.component.html',
  styleUrl: './page-footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageFooterComponent {}
