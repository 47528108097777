<div class="user-info">
  <div class="user-info__info-title truncate" [title]="infoTitle">{{ infoTitle }}</div>
  <ng-container *ngIf="userModification; else placeholderTemplate">
    <div class="user-info__context truncate" [title]="userModification | mpctmContractUserInfo">
      <span class="user-info__user">{{ userModification.user?.name }}</span
      >,
      {{ userModification.timestamp | date: 'dd.MM.yyyy' }}
    </div>
  </ng-container>
</div>

<ng-template #placeholderTemplate>
  <div class="placeholder-template">-</div>
</ng-template>
