import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { LetDirective } from '@ngrx/component';

import { IconDirective } from '@core/shared/util';
import { ContractStatusType } from '@mp/contract-management/common/domain';

import { ContractStatusIconPipe } from './contract-status-icon.pipe';
import { ContractStatusLabelPipe } from './contract-status-label.pipe';

@Component({
  selector: 'mpctm-contract-status',
  standalone: true,
  templateUrl: './contract-status.component.html',
  styleUrls: ['./contract-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, LetDirective, MatIconModule, ContractStatusIconPipe, ContractStatusLabelPipe, IconDirective],
})
export class ContractStatusComponent {
  @Input({ required: true }) statusType!: ContractStatusType;

  readonly cardCssClass = 'contract-status';

  getStatusClass(): string {
    const modifier: string = this.getStatusModifierClass();
    return `${this.cardCssClass} ${this.cardCssClass}--${modifier}`;
  }

  private getStatusModifierClass(): string {
    switch (this.statusType) {
      case ContractStatusType.Approved:
        return 'approved';
      case ContractStatusType.Archived:
        return 'archived';
      case ContractStatusType.Modified:
        return 'modified';
      case ContractStatusType.Created:
        return 'created';

      default:
        return '';
    }
  }
}
