import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LetDirective } from '@ngrx/component';

@Component({
  selector: 'mpctm-contract-validity',
  standalone: true,
  templateUrl: './contract-validity.component.html',
  styleUrls: ['./contract-validity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LetDirective, DatePipe],
})
export class ContractValidityComponent {
  @Input() validFrom?: string | null = null;
  @Input() validUntil?: string | null = null;
}
