import { ChangeDetectionStrategy, Component, InputSignal, Signal, computed, input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';

import { ContractStatusType } from '@mp/contract-management/common/domain';

@Component({
  selector: 'mpctm-contract-list-item-card',
  standalone: true,
  templateUrl: './contract-list-item-card.component.html',
  styleUrl: './contract-list-item-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatCardModule],
})
export class ContractListItemCardComponent {
  readonly statusType: InputSignal<ContractStatusType> = input.required<ContractStatusType>();

  protected readonly listItemClass: Signal<string> = computed(() => this.getListItemClass());

  private readonly cardCssClass = 'contract-list-item-card';

  private getListItemClass(): string {
    const modifier: string = this.getListItemModifierClass();
    return `${this.cardCssClass} ${this.cardCssClass}--${modifier}`;
  }

  private getListItemModifierClass(): string {
    switch (this.statusType()) {
      case ContractStatusType.Approved:
        return 'approved';
      case ContractStatusType.Archived:
        return 'archived';
      case ContractStatusType.Modified:
        return 'modified';
      case ContractStatusType.Created:
        return 'created';

      default:
        return '';
    }
  }
}
