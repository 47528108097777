import { Pipe, PipeTransform } from '@angular/core';

import { ContractStatusType } from '@mp/contract-management/common/domain';

@Pipe({
  name: 'mpctmContractStatusLabel',
  standalone: true,
})
export class ContractStatusLabelPipe implements PipeTransform {
  transform(contractStatusType: ContractStatusType): string {
    switch (contractStatusType) {
      case ContractStatusType.Approved:
        return 'FREIGEGEBEN';
      case ContractStatusType.Archived:
        return 'ARCHIVIERT';
      case ContractStatusType.Modified:
        return 'IN BEARBEITUNG';
      case ContractStatusType.Created:
        return 'NEU';

      default:
        return '';
    }
  }
}
