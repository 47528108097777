import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { LetDirective } from '@ngrx/component';

@Component({
  selector: 'mpctm-condition-date-change',
  standalone: true,
  templateUrl: './condition-date-change.component.html',
  styleUrl: './condition-date-change.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DatePipe, LetDirective],
})
export class ConditionDateChangeComponent {
  readonly dateValue: InputSignal<string | null> = input.required<string | null>();
}
